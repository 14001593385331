<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Team</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="teams-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('display_name')"
            :error-messages="errors['display_name']"
          ></v-text-field>

          <v-text-field
            label="Job title"
            v-model="fields.job_title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('job_title')"
            :error-messages="errors['job_title']"
          ></v-text-field>

          <v-text-field
            label="Email"
            v-model="fields.email"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>

          <v-text-field
            label="Phone"
            v-model="fields.phone"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>

          <v-file-input
            label="Photo"
            v-model="fields.photo"
            placeholder="Choose a File"
            outlined
            background-color="white"
            @change="photoHasChanged()"
            :error="errors.hasOwnProperty('photo')"
            :error-messages="errors['photo']"
          ></v-file-input>

          <v-textarea
            label="Bio"
            v-model="fields.bio"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('bio')"
            :error-messages="errors['bio']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="teams-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
        job_title: null,
        email: null,
        phone: null,
        photo: null,
        bio: null,
        photo_has_changed: false,
      },
      team: null,
      errors: {},
    };
  },

  methods: {
    photoHasChanged() {
      this.fields.photo_has_changed = true;
    },

    openForm: function (team = null) {
      if (team !== null) {
        this.isEditing = true;
        this.team = team;
        this.fields.name = team.name;
        this.fields.job_title = team.job_title;
        this.fields.email = team.email;
        this.fields.phone = team.phone;
        this.fields.bio = team.bio;
        this.fields.photo = team.photo;

        this.fields.photo = team.photo
          ? new File([team.photo], team.photo)
          : null;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.team = null;
      this.fields.name = null;
      this.fields.job_title = null;
      this.fields.email = null;
      this.fields.phone = null;
      this.fields.photo = null;
      this.fields.bio = null;
      this.fields.photo_has_changed = false;

      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.teamId = this.team.id;
      }

      this.$store
        .dispatch("easylets/teamsStore/saveTeam", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
